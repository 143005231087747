(function() {
  document.querySelector('#newsletter-subscribe-modal form').addEventListener('submit', function(e) {
    // Prevent default handler
    e.preventDefault();

    // Set form readonly flags on form elements
    const formElem = this;
    for (const inputElem of formElem.elements) {
      inputElem.readOnly = true;
    }

    // Submit form, then show success or failure modal
    submitForm(formElem)
        // Fetch only rejects on network errors, so we need to reject non-2XX response code here manually
        .then((response) => {
          if (!response.ok) throw new Error(response.status);
          else return response;
        })
        .then(submitSuccess)
        .catch(submitFailure)
        .finally(() => {
          // Reset readonly flags on form elements
          for (const inputElem of formElem.elements) {
            inputElem.readOnly = false;
          }
        });
  });

  /**
   * Submit handler for the form.
   * See: https://docs.netlify.com/forms/setup/#submit-html-forms-with-ajax
   *
   * @param {HTMLFormElement} formElem The form to submit.
   * @return {Promise<Response>} A promise that is resolved/rejected when the POST request completes.
   */
  function submitForm(formElem) {
    // Get the form data
    const formData = new FormData(formElem);

    // Submit form data via AJAX POST request
    return fetch('/', {
      method: 'POST',
      headers: {'Content-Type': 'application/x-www-form-urlencoded'},
      body: new URLSearchParams(formData).toString(),
    });
  }

  /**
   * Callback when the form is submitted successfully.
   */
  function submitSuccess() {
    // Hide the form and show a success modal
    Modal.getOrCreateInstance(document.getElementById('newsletter-subscribe-modal')).hide();
    Modal.getOrCreateInstance(document.getElementById('newsletter-subscribe-success-modal')).show();

    // Record analytics events
    if (typeof fbq === 'function') fbq('track', 'Lead', {content_name: 'Newsletter Subscription'});
    if (typeof gtag === 'function') gtag('event', 'generate_lead');
  }

  /**
   * Callback when the form submission fails.
   */
  function submitFailure() {
    //  Hide the form and show a failure modal
    Modal.getOrCreateInstance(document.getElementById('newsletter-subscribe-modal')).hide();
    Modal.getOrCreateInstance(document.getElementById('newsletter-subscribe-failure-modal')).show();
  }
})();
