(function() {
  const timers = document.querySelectorAll('[data-countdown]');
  Array.from(timers).forEach(initTimer);

  /**
   * Initialize the specified component as a timer.
   *
   * @param {Element} timer
   */
  function initTimer(timer) {
    // Set timer deadline as the end of the current month
    const now = new Date();
    const deadline = new Date(now.getFullYear(), now.getMonth()+1, 1);
    console.info('Initializing timer coutdown to ' + deadline);

    // Get the timespan difference between now and the deadline, in milliseconds
    let diffMs = deadline - now;

    // Set the timer value now
    setTimerString(diffMs);

    // Refresh the timer value every second
    // NOTE: This will drift over time, but we don't care
    const refreshMs = 1000;
    const intervalHandle = setInterval(() => {
      diffMs -= refreshMs;
      if (diffMs > 0) {
        setTimerString(diffMs);
      } else {
        // When timer ends cancel the interval loop
        timer.innerHTML =
          '<p>🎊 CONGRATULATIONS! YOU MADE IT TO THE END OF THE MONTH! 🎊</p>' +
          '<p>Refresh the page to count down to the end of the next month.</p>';
        clearInterval(intervalHandle);
      }
    }, refreshMs);

    /**
     * Set the string content for the timer element.
     *
     * @param {Number} diffMs The time to display in the element, in milliseconds.
     */
    function setTimerString(diffMs) {
      // Define some constants for use in this function
      const msPerSec = 1000;
      const msPerMin = msPerSec * 60;
      const msPerHour = msPerMin * 60;
      const msPerDay = msPerHour * 24;

      // Extract all the necessary components of the difference
      const numDays = Math.floor(diffMs / msPerDay);
      diffMs = diffMs % msPerDay;
      const numHours = Math.floor(diffMs / msPerHour);
      diffMs = diffMs % msPerHour;
      const numMins = Math.floor(diffMs / msPerMin);
      diffMs = diffMs % msPerMin;
      const numSecs = Math.floor(diffMs / msPerSec);

      // Set the timer text
      timer.textContent = `${numDays}d.${numHours}h.${numMins}m.${numSecs}s`;
    }
  }
})();
